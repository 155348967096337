import React, { useState, useEffect } from "react";

import { IRequest } from "../../tools/request";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import "./index.css";

import lastOne from "../../tools/lastOne";
import Wallets from "./../Wallets/Wallets";

interface Props {
	getSites: () => void;
	request: IRequest;
	name: string;
	id: string;
	callback: string;
	ballance: number;
}

const Site = (props: Props) => {
	const [onDeleting, setOnDeleting] = useState(false);
	const [name, setName] = useState(props.name || "");
	const [ballance, setBallance] = useState(props.ballance || 0);
	const [callback, setСallback] = useState(props.callback || "");

	useEffect(() => {
		lastOne(() => {
			props.request({
				endpoint: "Site",
				method: "post",
				payload: {
					id: props.id,
					name,
					ballance,
					callback,
				},
			});
		});
	}, [name, ballance, callback]);
	const deleteSite = async () => {
		setOnDeleting(true);
		props.request({
			endpoint: "Site",
			method: "delete",
			payload: {
				id: props.id,
			},
		});
		setTimeout(() => {
			props.getSites();
		}, 500);
	};
	return (
		<div className={`Site item ${onDeleting ? " onDeleting " : ""}`}>
			{/* <div className="id">{props.id}</div> */}
			<div className="top">
				<div className="name">
					<TextField
						onChange={(event) => {
							setName(event.target.value);
						}}
						// className="name"
						label="name"
						value={name}
					/>
				</div>
				<div className="callback">
					<TextField
						onChange={(event) => {
							setСallback(event.target.value);
						}}
						// className="callback"
						label="callback"
						value={callback}
					/>
				</div>
				<div className="ballance">
					<TextField
						type="number"
						onChange={(event) => {
							setBallance(parseInt(event.target.value));
						}}
						// className="ballance"
						label="ballance"
						value={ballance}
					/>
				</div>
				<div className="delete">
					<Button onClick={deleteSite}>
						<DeleteForeverIcon />
					</Button>
				</div>
			</div>

			<Wallets request={props.request} siteId={props.id} />
		</div>
	);
};

export default Site;
