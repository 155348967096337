import React from "react";
import { ResponsiveLine, Line } from "@nivo/line";
import { DatePicker, Select } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import "./Analitics.css";
import formatPrice from "./../../tools/formatPrice";
import Crart from "./Chart";
import { IAnalitics, ISite } from "./../../types";
import { Data } from "./types";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props {
	data: Data;
	sites: ISite[];
    site:any;
	setSite: (value: string) => void;
	onChange: (value: any) => void;
	// step: string;
}

const dateFormat = "YYYY/MM/DD";

const Analitics = (props: Props) => {
	console.log("props", props.data);
	// let total: any = 0;
	// props.data.forEach((item, index) => {
	// 	const _total = item.data.reduce(
	// 		(previousValue, currentValue) => previousValue + currentValue.sum,
	// 		0
	// 	);
	// 	total += _total;
	// });
	// total = formatPrice(total);
	return (
		<section className="Analitics">
			<div className="top">
				<Select
					style={{ minWidth: 200 }}
					defaultValue={props.site}
					onChange={(value) => {
						props.setSite(value);
					}}
				>
					<Option key={"all"} value={"all"}>
						all
					</Option>
					{props.sites.map((site) => (
						<Option key={site._id} value={site._id}>
							{site.name}
						</Option>
					))}
				</Select>
				<RangePicker
					onChange={props.onChange}
					// defaultValue={[
					// 	moment("2015/01/01", dateFormat),
					// 	moment("2015/01/01", dateFormat),
					// ]}
					format={dateFormat}
				/>
			</div>
			{/* <div className="chart-wrap">
				<Crart />
			</div> */}
			<div className="siteslist">
				{props.data.map((item) => {
					return (
						<div key={item.id} className="siteslist--site">
							<h3>{item.id}</h3>
							<ul>
								{item.data.map((_item, index) => {
									return (
										<li key={index}>
											<span>{_item.date}</span>
											<span className="siteslist-price">
												{formatPrice(_item.sum)}
											</span>
											<span>{_item.walletName}</span>
										</li>
									);
								})}
							</ul>
						</div>
					);
				})}
			</div>
		</section>
	);
};

export default Analitics;
