import React, { useState, useEffect } from "react";

import {
	BrowserRouter as Router,
	Switch,
	Route,
	NavLink,
} from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import TimelineIcon from "@material-ui/icons/Timeline";
import InfoIcon from "@material-ui/icons/Info";

import Products from "./components/products";
import Users from "./components/users";
import "./App.css";

import GroupIcon from "@material-ui/icons/Group";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import Button from "@material-ui/core/Button";

import Pagination from "./components/pagination";
import SortList from "./components/sortList";
import Search from "./components/Search";
import Exclude from "./components/Exclude";

import EditPanel from "./components/EditPanel";
import FilterCategory from "./components/FilterCategory";
import Login from "./components/Login";
import PermissionsDenied from "./components/PermissionsDenied";
import Popup from "./components/Popup";
import EditPresets from "./components/EditPresets";
import EditCats from "./components/EditCats";

import PaymentsApp from "./components/payments/App";

import Test from "./components/test_callback";

const theme = createMuiTheme({
	palette: {
		primary: lightBlue,
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	typography: {
		fontSize: 12,
	},
});

const App: React.FC = () => {
	const [isInit, setIsInit] = useState(true);
	const [totalPages, settotalPages] = useState(0);
	const [currentPage, settCurrentPage] = useState(1);
	const [categories, setCategories] = useState<Category[]>([]);
	const [presets, setPresets] = useState<Preset[]>([]);
	const [productOnPageIds, setProductOnPageIds] = useState([]);
	const [prods, setProds] = useState(true);

	const managerLS = JSON.parse(localStorage.getItem("manager") || `{}`);
	const [manager, setManager] = useState<Manager>(managerLS);

	// const [sites, setSites] = useState();
	const setPagination = (params: Pagination) => {
		if (params) {
			settotalPages(params.totalPages);
		}
	};
	//

	const reloadProducts = () => {
		// console.log('reloadProducts');
		setProds(false);
		setTimeout(() => {
			setProds(true);
		}, 100);
	};

	const [
		sortListItems,
		setSortListItems,
	] = useState<Array<SortListItem> | null>(null);
	const [currentSortIndex, setCurrentSortIndex] = useState(5);
	const [searchText, setSearchText] = useState("");
	const [excludeText, setExcludeText] = useState("");
	const [filterCategoryId, setFilterCategoryId] = useState(-1);

	const [popupIsOpen, setPopupIsOpen] = useState(false);
	const [popupContent, setPopupContent] = useState();

	const popup = (clild: any) => {
		setPopupContent(clild);
		setPopupIsOpen(true);
	};
	const openEditPresets = () => {
		popup(<EditPresets presets={presets} categories={categories} />);
	};
	const openEditCats = () => {
		popup(<EditCats categories={categories} />);
	};
	useEffect(() => {
		if (manager.login && manager.password) {
			fetch(`https://yesbeat.ru/admin-api/index.php?initial=true`, {
				method: "post",
				body: JSON.stringify({ manager: manager }),
			})
				.then((res) => res.json())
				.then((res) => {
					console.log("app init res", res);
					if (res.error) {
						alert("error " + res.error);
					} else {
						setCategories(res.categories);
						setPresets(res.presets);
						setIsInit(false);
					}
				});
			//
			localStorage.setItem("manager", JSON.stringify(manager));
		} else {
			localStorage.removeItem("token");
		}
	}, [manager]);
	if (!manager.login || !manager.password) {
		return (
			<MuiThemeProvider theme={theme}>
				<Login setManager={setManager} manager={manager} />
			</MuiThemeProvider>
		);
	}
	if (isInit) {
		return null;
	}
	return (
		<Router>
			<MuiThemeProvider theme={theme}>
				<div className="App">
					<header className="App-header">
						<div className="search-wrap">
							<div id="manager">
								<Button
									variant="outlined"
									color="primary"
									onClick={() => {
										setManager({});
                                        localStorage.clear();
									}}
								>
									Log out
								</Button>
								<span>{manager.login} </span>
							</div>
							<Search
								setSearchText={setSearchText}
								searchText={searchText}
							/>
							<Exclude
								setExcludeText={setExcludeText}
								excludeText={excludeText}
							/>
							<FilterCategory
								categories={categories}
								setFilterCategoryId={setFilterCategoryId}
								filterCategoryId={filterCategoryId}
							/>
						</div>
						<div id="header-navs">
							<nav>
								<NavLink
									activeClassName="is-active"
									to="/users"
									exact
								>
									<GroupIcon style={{ fontSize: 35 }} />
								</NavLink>
								<NavLink
									activeClassName="is-active"
									to="/"
									exact
								>
									<QueueMusicIcon style={{ fontSize: 35 }} />
								</NavLink>
								{manager?.permissions?.includes("payments") ? (
									<>
										<NavLink
											activeClassName="is-active"
											to="/payments/wallets/"
											exact
										>
											<AccountBalanceWalletIcon
												style={{ fontSize: 35 }}
											/>
										</NavLink>

										<NavLink
											activeClassName="is-active"
											to="/payments/history/"
											exact
										>
											<InfoIcon
												style={{ fontSize: 35 }}
											/>
										</NavLink>
										<NavLink
											activeClassName="is-active"
											to="/payments/analitics/"
											exact
										>
											<TimelineIcon
												style={{ fontSize: 35 }}
											/>
										</NavLink>
									</>
								) : null}
							</nav>
							<SortList
								items={sortListItems}
								currentSortIndex={currentSortIndex}
								setCurrentSortIndex={setCurrentSortIndex}
							/>
							<Pagination
								settCurrentPage={settCurrentPage}
								totalPages={totalPages}
								currentPage={currentPage}
							/>
						</div>
					</header>
					<main>
						<Switch>
							<Route exact path="/test_callback/">
								<Test />
							</Route>

							<Route path="/payments/">
								{manager?.permissions?.includes("payments") ? (
									<PaymentsApp />
								) : null}
							</Route>

							<Route exact path="/users">
								{(manager?.permissions &&
									manager?.permissions.includes("users")) ||
								(manager?.permissions &&
									manager?.permissions.includes("all")) ? (
									<Users
										manager={manager}
										currentPage={currentPage}
										setPagination={setPagination}
										currentSortIndex={currentSortIndex}
										setSortListItems={setSortListItems}
									/>
								) : (
									<PermissionsDenied />
								)}
							</Route>
							<Route path="/" exact>
								{(manager &&
									manager.permissions &&
									manager.permissions.includes("products")) ||
								(manager &&
									manager.permissions &&
									manager.permissions.includes("all")) ? (
									<>
										<EditPanel
											categories={categories}
											productOnPageIds={productOnPageIds}
											presets={presets}
											reloadProducts={reloadProducts}
										/>
										{prods ? (
											<Products
												openEditPresets={
													openEditPresets
												}
												openEditCats={openEditCats}
												manager={manager}
												categories={categories}
												currentPage={currentPage}
												setPagination={setPagination}
												currentSortIndex={
													currentSortIndex
												}
												setSortListItems={
													setSortListItems
												}
												searchText={searchText}
												excludeText={excludeText}
												setProductOnPageIds={
													setProductOnPageIds
												}
												filterCategoryId={
													filterCategoryId
												}
												presets={presets}
											/>
										) : null}
									</>
								) : (
									<PermissionsDenied />
								)}
							</Route>
						</Switch>
					</main>
					<aside></aside>
					<Popup
						isOpen={popupIsOpen}
						setPopupIsOpen={setPopupIsOpen}
						content={popupContent}
					></Popup>
				</div>
			</MuiThemeProvider>
		</Router>
	);
};

export default App;
