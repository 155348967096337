import React, { useState, useEffect } from "react";

import History from "./History";
import { IHistory } from "./../../types";

import request from "./../../tools/request";

interface Props {}

const ContainerHistory = (props: Props) => {
	const [items, setItems] = useState([]);

    
	const refreshHistory = () => {
		request({
			endpoint: "history",
		}).then((res) => {
			setItems(res);
		});
	};

	useEffect(() => {
		refreshHistory();
		const timerId = setInterval(refreshHistory, 10000);
		return () => clearInterval(timerId);
	}, []);

	return <History items={items} />;
};

export default ContainerHistory;
