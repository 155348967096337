import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { version } from "./../../package.json";

interface Props {
	manager: Manager;
	setManager: (manager: Manager) => void;
}

const Login: React.FC<Props> = (props: Props) => {
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const manager: Manager = {
		login,
		password,
	};
	const submit = () => {
		fetch(`https://yesbeat.ru/admin-api/index.php`, {
			method: "post",
			body: JSON.stringify({ manager: manager }),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log("app Login res", res);
				if (res.error) {
					alert("error " + res.error);
				} else {
					props.setManager(res.manager);
				}
			});
	};
    const onSubmit = (event:any)=>{
        event.preventDefault()
    }
	return (
		<form action="#" id="login-form" onSubmit={onSubmit}>
			<span style={{ padding: "20px" }}>version: {version}</span>
			<TextField
				onChange={(event) => {
					setLogin(event.target.value);
				}}
				name="Login"
				label="Login"
				value={login}
			/>
			<TextField
				onChange={(event) => {
					setPassword(event.target.value);
				}}
				name="Password"
				label="Password"
				value={password}
			/>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				onClick={submit}
			>
				Submit
			</Button>
		</form>
	);
};

export default Login;
