import React, { useEffect, useState } from "react";
import { IWallet, ISite } from "./../../types";
import { IRequest } from "../../tools/request";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Wallet from "../Wallet/Wallet";
import "./index.css"

interface Props {
	request: IRequest;
	siteId: string;
}

const Wallets = (props: Props) => {
	const [wallets, setWallets] = useState<IWallet[]>([]);
	const getWallets = () => {
		props
			.request({ endpoint: "wallet", payload: { siteId: props.siteId } })
			.then((res: any) => {
				setWallets(res);
			});
	};

	useEffect(() => {
		getWallets();
	}, []);
	const addWallet = async () => {
		const wallet = await props.request({
			endpoint: "wallet",
			method: "post",
			payload: { siteId: props.siteId },
		});
		setWallets([...wallets, wallet]);
	};
	return (
		<section className="Wallets">
			<div className="items">
				{wallets.map((item) => (
					<Wallet
						getWallets={getWallets}
						key={item._id}
						name={item.name}
						id={item._id}
						secret={item.secret}
						ballance={item.ballance}
						request={props.request}
						mirror={item.mirror}
					/>
				))}
			</div>
			<div className="controls">
				<Button variant="contained" color="primary" onClick={addWallet}>
					<AddCircleOutlineIcon /> add Wallet
				</Button>
			</div>
		</section>
	);
};

export default Wallets;
