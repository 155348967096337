import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Site from "./../Site/Site";
import { ISite } from "./../../types";
import request from "./../../tools/request";

interface Props {}

const Sites = (props: Props) => {
	const [sites, setSites] = useState<ISite[]>([]);
	const getSites = () => {
		request({ endpoint: "site" }).then((res: any) => {
			res && setSites(res);
		});
	};
	useEffect(() => {
		getSites();
	}, []);
	const addSite = async () => {
		const site = await request({ endpoint: "site", method: "post" });
		setSites([...sites, site]);
	};
	return (
		<section className="Sites">
			<div className="items">
				{sites.map((item) => (
					<Site
						getSites={getSites}
						request={request}
						key={item._id}
						name={item.name}
						id={item._id}
						ballance={item.ballance}
						callback={item.callback}
					/>
				))}
			</div>
			<div className="controls">
				<Button variant="contained" color="primary" onClick={addSite}>
					<AddCircleOutlineIcon /> add Site
				</Button>
			</div>
		</section>
	);
};

export default Sites;
