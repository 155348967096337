import { useState, useEffect, FC } from "react";
import { Route } from "react-router-dom";
import History from "./components/History/Container";

import Sites from "./components/Sites/Sites";
import Analitics from "./components/Analitics/Container";
import "./App.css";

const App: FC = () => {
	return (
		<div className="AppPayments">
			<Route path="/payments/wallets/">
				<Sites />
			</Route>
			<Route path="/payments/history/">
				<History />
			</Route>
			<Route path="/payments/analitics/">
				<Analitics />
			</Route>
		</div>
	);
};
export default App;
