import React, { useState, useEffect, useRef } from "react";
import { IRequest } from "../../tools/request";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import "./index.css";

import lastOne from "../../tools/lastOne";

const clearUrl = (url: string) => {
	return url
		.replace("https://", "")
		.replace("http://", "")
		.replace("/", "")
		.replace(" ", "");
};

interface Props {
	getWallets: () => void;
	request: IRequest;
	name: string;
	id: string;
	secret: string;
	ballance: number;
	mirror: string;
}

const Wallet = (props: Props) => {
	const [onDeleting, setOnDeleting] = useState(false);
	const [name, setName] = useState(props.name || "");
	const [ballance, setBallance] = useState(props.ballance || 0);
	const [secret, setSecret] = useState(props.secret || "");
	const [mirror, setMirror] = useState(props.mirror || "");

	useEffect(() => {
		lastOne(() => {
			props.request({
				endpoint: "wallet",
				method: "post",
				payload: {
					id: props.id,
					name,
					ballance,
					secret,
					mirror,
				},
			});
		});
	}, [name, ballance, secret, mirror]);
	const deleteWallet = async () => {
		setOnDeleting(true);
		props.request({
			endpoint: "wallet",
			method: "delete",
			payload: {
				id: props.id,
			},
		});
		setTimeout(() => {
			props.getWallets();
		}, 500);
	};
	return (
		<div className={`Wallet item ${onDeleting ? " onDeleting " : ""}`}>
			{/* <div className="id">{props.id}</div> */}
			<div className="name">
				<TextField
					onChange={(event) => {
						setName(event.target.value);
					}}
					// className="name"
					label="id"
					value={name}
				/>
			</div>
			<div className="secret">
				<TextField
					onChange={(event) => {
						setSecret(event.target.value);
					}}
					// className="callback"
					label="secret"
					value={secret}
				/>
			</div>
			<div className="mirror">
				<TextField
					onChange={(event) => {
						setMirror(clearUrl(event.target.value));
					}}
					// className="callback"
					label="mirror"
					value={mirror}
				/>
			</div>
			<div className="ballance">
				<TextField
					type="number"
					onChange={(event) => {
						setBallance(parseInt(event.target.value));
					}}
					// className="ballance"
					label="ballance"
					value={ballance}
				/>
			</div>
			<div className="delete">
				<Button onClick={deleteWallet}>
					<DeleteForeverIcon />
				</Button>
			</div>
		</div>
	);
};

export default Wallet;
