import React, {  } from "react";


const PermissionsDenied: React.FC= () => {
	return (
		<section id="PermissionsDenied">
            <img src="https://yesbeat.ru/admin/public/assets/images/perm.png" alt=""/>
            <span>Permission denied</span>
		</section>
	);
};

export default PermissionsDenied;
