import lastOne from "./lastOne";

export type IRequest = (req: {
	endpoint: string;
	payload?: any;
	method?: "post" | "get" | "delete";
}) => Promise<any>;
// import {useHistory} from "react-router-dom"

const request: IRequest = async (req) => {
	const token = localStorage.getItem("token");
	if (!token && req.endpoint !== "login") {
		const _manager = localStorage.getItem("manager");
		if (_manager) {
			lastOne(async () => {
				const manager = JSON.parse(_manager + "");
				console.log("manager", manager);
				const loginRes = await request({
					endpoint: "login",
					method: "post",
					payload: {
						username: manager.login,
						password: manager.password,
					},
				});
				console.log("loginRes", loginRes);

				if (loginRes.accessToken) {
					localStorage.setItem("token", loginRes.accessToken);
					await request(req);
					window.location.reload();
				} else {
					alert("error get token");
				}
			});
		}
	} else {
		const domen =
			process.env.NODE_ENV === "development"
				? "http://localhost:5000"
				: "https://yoo.wesetup.ru";
		const method = req.method || "get";
		const params: any = {
			method,
			headers: {
				"Content-Type": "application/json",
			},
		};
		if (token) {
			params.headers.authorization = token;
		}
		let getPayload = "";
		if (method === "get") {
			getPayload = "?" + new URLSearchParams(req.payload).toString();
		} else {
			params.body = JSON.stringify(req.payload);
		}
		return await fetch(
			`${domen}/${req.endpoint}${getPayload}`,
			params
		).then((res) => res.json());
	}
};
export default request;
