import { useEffect, useState } from "react";
import Analitics from "./Analitics";
import { IAnalitics, ISite, IWallet } from "./../../types";
import request from "./../../tools/request";
import moment, { Moment } from "moment";
import formatPrice from "./../../tools/formatPrice";
import { Data } from "./types";

interface Props {}

const Container = (props: Props) => {
	const [data, setData] = useState<Data>([]);
	const [sites, setSites] = useState<ISite[]>([]);
	const [wallets, setWallets] = useState<IWallet[]>([]);
	const [site, setSite] = useState("all");
	const [dates, setDates] = useState<string[]>([]);
	const [step, setStep] = useState<string>("every 1 days");

	const prepareData = (data: IAnalitics[]): Data => {
		console.log("data", data);
        // @ts-ignore
		if (!data || data.error) {
			return [];
		}
		const _sites =
			site === "all"
				? sites
				: sites.filter((_site) => _site._id === site);
		return _sites.map((site) => {
			const siteAnalitics = data.filter(
				(_item) => _item.siteId === site._id
			);
			const _data = siteAnalitics.map((siteAnalitic) => {
				const wallet = wallets.filter(
					(_wallet) => _wallet._id === siteAnalitic.walletId
				);
				return {
					date: moment(siteAnalitic.createdAt).format("Y-MM-D HH:mm"),
					sum: siteAnalitic.sum,
					walletName: wallet?.[0]?.name || siteAnalitic.walletId,
				};
			});
			//
			const siteTotal = _data.reduce(
				(previousValue, currentValue) =>
					previousValue + currentValue.sum,
				0
			);
			return {
				id: site.name + " " + formatPrice(siteTotal),
				data: _data,
			};
		});
	};

	const refreshData = async () => {
		const _data = await request({
			endpoint: "analitics",
			payload: {
				siteId: site === "all" ? "" : site,
				dates: JSON.stringify(dates),
			},
		});
		setData(prepareData(_data));
	};
	useEffect(() => {
		(async () => {
			const wallets = await request({
				endpoint: "site",
			});
			setWallets(wallets);
			const sites = await request({
				endpoint: "site",
			});
			setSites(sites);
		})();
		refreshData();
	}, []);
	useEffect(() => {
		refreshData();
	}, [site, sites, dates]);
	useEffect(() => {
		if (dates[0] === dates[1]) {
			// setStep("every 1 days");
		} else {
			// setStep("every 1 hours");
		}
	}, [dates]);
	const onChange = (value: any) => {
		const formatDate = (m: Moment) => {
			return m.format("YYYY-MM-DD");
		};
		if (value?.[0]) {
			setDates([formatDate(value[0]), formatDate(value[1])]);
		} else {
			setDates([]);
		}
		console.log(value);
		console.log("dates", dates);
	};
	if (data.length === 0) {
		return <p>empty data</p>;
	}
	if (sites.length === 0) {
		return <p>empty sites</p>;
	}
	return (
		<Analitics
			onChange={onChange}
			sites={sites}
			setSite={setSite}
			site={site}
			data={data}
		></Analitics>
	);
};

export default Container;
