import React from "react";
import { IHistory } from "./../../types";
import "./index.css";

interface Props {
	items: IHistory[];
}

const History = (props: Props) => {
	return (
		<section className="History">
			History
			<div className="items">
				{props.items.map((item) => (
					<Item key={item._id} {...item} />
				))}
			</div>
		</section>
	);
};

export default History;

const Item = (props: IHistory) => {
	let style = `item`;
	if (props.success) {
		style += " success";
	}
	if (props.error) {
		style += " error";
	}
	return (
		<div className={style}>
			<span className="status">{props.success ? "успешно оплачен" : props.error}</span>
			<span>{props.siteName ? props.siteName : props.siteId}</span>
			<span>{props.walletId}</span>
		</div>
	);
};
