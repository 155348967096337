import React from "react";

interface Props {}

const test_callback = (props: Props) => {
	const host = ["test1.com", "test2.com", "test3.com", "test4.com"][
		Math.floor(Math.random() * Math.floor(3))
	];

	const testReq: any = {
		notification_type: "card-incoming",
		zip: "",
		bill_id: "",
		amount: "5.00",
		firstname: "",
		codepro: "false",
		withdraw_amount: "5.10",
		city: "",
		unaccepted: "false",
		label: "24980",
		building: "",
		lastname: "",
		datetime: "2021-02-08T11:11:53Z",
		suite: "",
		sender: "",
		phone: "",
		sha1_hash: "6e406d9e546028c1785dae05adb10f6e0183e59a",
		street: "",
		flat: "",
		fathersname: "",
		operation_label: "27b33026-0011-5000-9000-1c6e9d953f08",
		operation_id: "666097913121002404",
		currency: "643",
		email: "",
	};
	const formData = new FormData();

	Object.keys(testReq).forEach((key) => {
		formData.append(key, testReq[key]);
	});
	const test = async () => {
		fetch("http://test2.wesetup.ru/callback", {
			method: "post",
			body: formData,
		})
			.then((_res) => _res.json())
			.then((_res) => {
				console.log("test", _res);
			})
			.catch((err) => {
				if (err) {
					console.log("test err", err);
				}
			});
	};

	return (
		<div>
			<section>
				<form method="POST" action="http://test2.wesetup.ru/callback">
					{Object.keys(testReq).map((key: any) => {
						return (
							<input
								type="text"
								name={key}
								value={testReq[key]}
							/>
						);
					})}
					<button type="submit"> Перейти к оплате </button>
				</form>
			</section>
			{/* <button onClick={test}>test</button> */}
		</div>
	);
};
/*
https://yesbeat.ru/payment/Yandex/callback_api.php
test2.wesetup.ru
41001708275972
VB268wqyidIsZAJfEeqYSzKm
*/
export default test_callback;
