let lastFunc: any = null;
let timerId: any = null;
const lastOne = (func: () => void, delay: number = 300) => {
	lastFunc = func;
	if (timerId) {
		clearInterval(timerId);
	}
	timerId = setTimeout(() => {
		lastFunc();
	}, delay);
};

export default lastOne;
